<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
        detailHide,
      ]"
    >
      <!-- <vs-button
				v-on:click="doPaymentApproval()"
				color="primary"
				icon-pack="feather"
				icon="icon-inbox"
				>Approval</vs-button
			> -->
      
        <vs-button
          v-on:click="submitPrint()"
          color="primary"
          icon-pack="feather"
          icon="icon-printer"
          >Print</vs-button
        >
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th width="5%"></vs-th>
          <vs-th>
            <vs-checkbox
              v-on:click="printAll()"
                v-model="statusPrintAll"
              color="primary"
              >Print</vs-checkbox
            >
          </vs-th>
          <vs-th>Supplier</vs-th>
          <vs-th sort-key="dn_code">DN Number</vs-th>
          <vs-th>Territory</vs-th>
          <vs-th>Settlement Method</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            <vs-td class="whitespace-no-wrap">
              <template>
                <vx-tooltip text="Show">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="handleEdit(tr, indextr)"
                    icon="icon-eye"
                  />
                </vx-tooltip>
                <vx-tooltip text="Print">
                    <vs-button
                      color="success"
                      type="line"
                      icon-pack="feather"
                      @click="print(tr)"
                      icon="icon-printer"
                    />
                </vx-tooltip>
              </template>
            </vs-td>
            <vs-td>
              <vs-checkbox
                v-on:click="
                  printdn(
                    indextr,
                    data[indextr].dn_id
                  )
                "
                :checked="dn_id_print.includes(tr.dn_id)"
                color="primary"
              ></vs-checkbox>
            </vs-td>
            <vs-td :data="data[indextr].supplier_name">
              <div class="vx-row ml-0">
                <span class="w-5/5"
                  >{{ data[indextr].supplier_code }} -
                  {{ data[indextr].supplier_name }}</span
                >
              </div>
              <div class="vx-row ml-0">
                <span class="w-5/5"
                  >{{ data[indextr].supplier_address }},
                  {{ data[indextr].supplier_city }} ({{
                    data[indextr].supplier_contact_name
                  }}
                  - {{ data[indextr].supplier_phone }})</span
                >
              </div>
            </vs-td>
            <vs-td>              
              DN Number : {{ data[indextr].dn_code ? data[indextr].dn_code : "-" }} <br>
              DN Date : {{ data[indextr].dn_date ? data[indextr].dn_date : "-" }} <br>
              Ref Number : {{data[indextr].dn_reference_code?data[indextr].dn_reference_code:'-'}}
            </vs-td>
            <vs-td v-if="data[indextr].territories.length >= 1">     
              <div v-for="item in data[indextr].territories" :key="item.code">
              {{ item.code }} <br />
              {{ item.name }}
              </div>         
            </vs-td>
            <vs-td v-else>     
              {{ data[indextr].territory.code }} <br />
              {{ data[indextr].territory.name }} 
            </vs-td>            
            <vs-td>              
             {{ data[indextr].settlement ? data[indextr].settlement : "" }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            class="vertical-divider"
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <!-- form component -->
          <detail
            @close="handleClose"
            :dataDN="dataDN"
          >
          </detail>
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import Detail from "./detail.vue";
export default {
  components: {
    Detail,
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      index1: 0,
      data: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      notes: "",
      remaining_dn_value: 0,
      dataDN:[],
      statusPrintAll: false,
      dn_id_print: [],
      check_page: [],
    };
  },
  methods: {
    print(tr) {
      console.log(tr);
      let name = "dn-manual-print";

      let routeData = this.$router.resolve({
        name: name,
        params: { 
          id: tr.dn_id, 
        },
      });
      console.log(routeData.href);
      console.log(routeData);
      window.open(routeData.href, "_blank");
      // window.print()
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      if(this.check_page.includes(page)){
        this.statusPrintAll = true;
      }else{
        this.statusPrintAll = false;
      }
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      if (key != null) {
        console.log("key", key)
      this.table.order = key;
      this.table.sort = active;
      this.getData();
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/dn", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.$userLogin.territory_id,
            territory_area_id: this.$userLogin.territory_area_id,
            status: 1,
            type: 'manual'
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_records;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_seardh;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleCreate() {
      this.detail = true;
    },
    handleReversal(id) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/debit-note/reversal/" + id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData()
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(data, index, remaining) {
      console.log("index", index);
      console.log("index1", this.index1);
      this.dn_id = data.dn_id;
      this.notes = data.notes;
      this.dn_code = data.dn_code;
      this.cn_number = data.cn_number;
      this.cn_date = data.cn_date == "0001-01-01" ? "" : data.cn_date;
      this.tr_number = data.tr_number;
      this.tr_date = data.tr_date == "0001-01-01" ? "" : data.tr_date;
      this.remaining_dn_value = remaining;
      this.table.data = [];
      this.table.data = this.data;
      this.dataDN = data;

      this.table.data[this.index1].class = "";
      this.table.data[index].class = "highlight";
      this.detail = true;
      this.index1 = index;
    },
    printAll() {
      if (!this.statusPrintAll){
        this.check_page.push(this.table.page)
      }else{
          var index3 = this.check_page.indexOf(this.table.page);
          if (index3 !== -1) {
            this.check_page.splice(index3, 1);
          }
      }
      console.log("this.check_page", this.check_page);
      let _this = this;
      this.table.data.forEach(function (element) {
        if (!_this.dn_id_print.includes(element.dn_id)) {
          _this.dn_id_print.push(element.dn_id);
        } else {
          var index2 = _this.dn_id_print.indexOf(element.dn_id);
          if (index2 !== -1) {
            _this.dn_id_print.splice(index2, 1);
          }
        }
      });
      console.log("printAll", this.dn_id_print);

    },
    printdn(index, dn_id) {
      console.log("printdn");
      if (!this.dn_id_print.includes(dn_id)) {
        this.dn_id_print.push(dn_id);
      } else {
        var index2 = this.dn_id_print.indexOf(dn_id);
        if (index2 !== -1) {
          this.dn_id_print.splice(index2, 1);
        }
      }
      console.log(this.dn_id_print);
    },
    submitPrint() {
      if (this.dn_id_print.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Select data first.",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }else{
        console.log("dn-manual-print-all", this.dn_id_print);
        let name = "dn-manual-print-all";

        let routeData = this.$router.resolve({
          name: name,
          query: {
            ids: this.dn_id_print, 
          },
          // params: { 
          //   ids: this.dn_id_print, 
          // },
        });
        console.log(routeData.href);
        console.log(routeData);
        window.open(routeData.href, "_blank");

      }
    },
  },
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>